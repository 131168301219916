const {
  VCSTATE,
  JSBundles,
  CSSBundles,
  TranslationsBundles,
  BVVars,
} = window;

const casinoSectionComponents = {
  app: JSBundles.casinoSectionComponents,
  dependencies: [
    JSBundles.casinoGlobal,
    CSSBundles.casinoV2,
  ],
};

const genericQuestionnaire = {
  app: JSBundles.questionnaire,
  dependencies: [
    CSSBundles.questionnaire,
  ],
  getter: () => window.Questionnaire,
};

const sportSections = {
  app: JSBundles.sportSections,
  dependencies: [
    JSBundles.sportsbook,
    JSBundles.sportsbookCommon,
    JSBundles.sportsbookOverview,
    CSSBundles.inPlayOverview,
    CSSBundles.priceItUp,
    CSSBundles.errorPage,
    CSSBundles.betBoost,
    CSSBundles.eventLevel,
    CSSBundles.scoreboard,
  ],
};

const selfExclude = {
  app: JSBundles.selfExclude,
  dependencies: [
    CSSBundles.selfExclude,
    CSSBundles.accountForms,
  ],
  softDependencies: [
    TranslationsBundles.selfExclusion,
  ],
  getter: () => window.SelfExclude,
};

const stakeLimitsCommon = {
  app: JSBundles.stakeLimits,
  dependencies: [
    CSSBundles.stakeLimits,
    CSSBundles.accountForms,
  ],
  getter: () => window.StakeLimits,
};

const occupationAndSalary = {
  app: JSBundles.occupationAndSalary,
  dependencies: [
    CSSBundles.occupationAndSalary,
    CSSBundles.saferGamblingPortal,
    JSBundles.validations,
  ],
  softDependencies: [
    TranslationsBundles.occupationAndSalary,
  ],
  getter: () => window.OccupationAndSalary,
};

export default {
  homeComponents: {
    app: JSBundles.homeComponents,
    getter: () => window.HomeComponents,
  },
  banners: {
    app: JSBundles.banners,
    dependencies: [
      CSSBundles.banners,
    ],
    getter: () => window.BVBanners,
  },
  featured: {
    app: JSBundles.featured,
    dependencies: [
      JSBundles.sportsbook,
      CSSBundles.eventLevel, // TODO: convert event level to generic sportsbook styles
      CSSBundles.scoreboard,
    ],
    getter: () => window.Featured,
  },
  upcomingRaces: {
    app: JSBundles.upcomingRaces,
    dependencies: [
      JSBundles.sportsbook,
      JSBundles.blazy,
    ],
    getter: () => window.UpcomingRaces,
  },
  trendingBets: {
    app: JSBundles.trendingBets,
    dependencies: [
      JSBundles.sportsbook,
    ],
    getter: () => window.TrendingBets,
  },
  priceItUp: {
    app: JSBundles.priceItUp,
    dependencies: [
      CSSBundles.priceItUp,
      CSSBundles.betBuilder,
      JSBundles.sportsbook,
    ],
    getter: () => window.PriceItUp,
  },
  gamesList: {
    ...casinoSectionComponents,
    getter: () => ({ default: window.CasinoSectionComponents.GamesList }),
  },
  videoGamesList: {
    ...casinoSectionComponents,
    getter: () => ({ default: window.CasinoSectionComponents.VideoGamesList }),
  },
  liveCasinoGamesList: {
    ...casinoSectionComponents,
    getter: () => ({ default: window.CasinoSectionComponents.LiveCasinoGamesList }),
  },
  widget: {
    ...casinoSectionComponents,
    getter: () => ({ default: window.CasinoSectionComponents.Widget }),
  },
  seo: {
    app: JSBundles.seo,
    getter: () => window.SeoComponent,
  },
  userInboxIcon: {
    app: JSBundles.userInboxIcon,
    getter: () => window.UserInboxIcon,
  },
  settings: {
    app: JSBundles.settings,
    dependencies: [
      CSSBundles.settings,
    ],
    softDependencies: [
      TranslationsBundles.acceptAllPriceChangesToggle,
      TranslationsBundles.acceptAllLineChangesToggle,
    ],
    getter: () => window.Settings,
  },
  userInbox: {
    app: JSBundles.userInboxModal,
    dependencies: [
      CSSBundles.userInbox,
    ],
    getter: () => window.UserInboxModal,
  },
  userMessages: {
    app: JSBundles.userMessages,
    getter: () => window.UserMessages,
  },
  accountOverview: {
    app: JSBundles.accountOverview,
    dependencies: [
      CSSBundles.accountOverview,
    ],
    softDependencies: [
      // Account overview uses portal translations
      // From now on this should change, even if some keys are duplicated in different scopes
      TranslationsBundles.saferGamblingPortal,
    ],
    getter: () => window.AccountOverview,
  },
  referAFriend: {
    app: JSBundles.referAFriend,
    dependencies: [
      CSSBundles.referAFriend,
      CSSBundles.offers,
    ],
    getter: () => window.ReferAFriend,
  },
  referAFriendAsia: {
    app: JSBundles.referAFriendAsia,
    dependencies: [
      CSSBundles.referAFriendAsia,
      CSSBundles.offers,
    ],
    getter: () => window.ReferAFriendAsia,
  },
  sowCollection: {
    app: JSBundles.sowCollection,
    dependencies: [
      CSSBundles.sowCollection,
    ],
    softDependencies: [
      TranslationsBundles.kycStrategies,
      TranslationsBundles.sowCollection,
    ],
    getter: () => window.SOWCollection,
  },
  cashierActions: {
    app: JSBundles.cashierActions,
    dependencies: [
      CSSBundles.cashierActions,
      CSSBundles.liveChat,
      CSSBundles.accountForms,
    ],
    softDependencies: [
      TranslationsBundles.stakeLimits,
      TranslationsBundles.payments,
      TranslationsBundles.quickDeposit,
    ],
    getter: () => window.CashierActions,
  },
  contactUs: {
    app: JSBundles.contactUs,
    dependencies: [
      CSSBundles.contactUs,
    ],
    getter: () => window.ContactUs,
  },
  requestCallback: {
    app: JSBundles.requestCallback,
    dependencies: [
      JSBundles.validations,
      CSSBundles.requestCallback,
      CSSBundles.registration,
      CSSBundles.accountForms,
      CSSBundles.intTelInput,
    ],
    getter: () => window.RequestCallback,
  },
  accountHistory: {
    app: JSBundles.accountHistory,
    dependencies: [
      CSSBundles.offers,
      CSSBundles.accountHistory,
    ],
    getter: () => window.AccountHistory,
  },
  passwordChange: {
    app: JSBundles.passwordChange,
    dependencies: [
      JSBundles.validations,
      CSSBundles.accountForms,
      CSSBundles.passwordChange,
    ],
    getter: () => window.PasswordChange,
  },
  passwordReset: {
    app: JSBundles.passwordReset,
    dependencies: [
      JSBundles.validations,
      CSSBundles.accountForms,
      CSSBundles.sendVerifyCode,
      CSSBundles.liveChat,
    ],
    getter: () => window.PasswordReset,
  },
  registration: {
    app: JSBundles.registration,
    dependencies: [
      JSBundles.validations,
      CSSBundles.occupationAndSalary,
      CSSBundles.registration,
      CSSBundles.accountForms,
      CSSBundles.intTelInput,
      CSSBundles.liveChat,
    ],
    softDependencies: [
      TranslationsBundles.occupationAndSalary,
    ],
    features: {
      occupationAndSalary,
    },
    getter: () => window.Registration,
  },
  verificationZone: {
    app: JSBundles.verificationZone,
    dependencies: [
      CSSBundles.verificationZone,
    ],
    softDependencies: [
      TranslationsBundles.kycStrategies,
      TranslationsBundles.saferGamblingPortal,
      TranslationsBundles.sowCollection,
    ],
    getter: () => window.VerificationZone,
  },
  personalDetails: {
    app: JSBundles.personalDetails,
    dependencies: [
      JSBundles.validations,
      CSSBundles.personalDetails,
      CSSBundles.sendVerifyCode,
      CSSBundles.accountForms,
      CSSBundles.occupationAndSalary,
      CSSBundles.intTelInput,
    ],
    softDependencies: [
      TranslationsBundles.saferGamblingPortal,
      TranslationsBundles.occupationAndSalary,
    ],
    getter: () => window.PersonalDetails,
    features: {
      occupationAndSalary,
    },
  },
  questionnaire: genericQuestionnaire,
  saferGamblingQuestionnaire: {
    app: JSBundles.saferGamblingQuestionnaire,
    softDependencies: [
      TranslationsBundles.saferGamblingQuestionnaire,
    ],
    getter: () => window.SaferGamblingQuestionnaire,
  },
  saferGamblingPortal: {
    app: JSBundles.saferGamblingPortal,
    dependencies: [
      CSSBundles.saferGamblingPortal,
    ],
    softDependencies: [
      TranslationsBundles.saferGamblingPortal,
      TranslationsBundles.occupationAndSalary,
    ],
    getter: () => window.SaferGamblingPortal,
    features: {
      contactUs: {
        app: JSBundles.contactUs,
        dependencies: [
          CSSBundles.contactUs,
        ],
        getter: () => ({ default: window.ContactUs.WithoutModal }),
      },
      timeOut: {
        app: JSBundles.timeOut,
        dependencies: [
          JSBundles.validations,
          CSSBundles.timeOut,
          CSSBundles.accountForms,
        ],
        softDependencies: [
          TranslationsBundles.timeOut,
        ],
        getter: () => window.TimeOut,
      },
      occupationAndSalary,
      selfExclusion: selfExclude,
      sgQuestionnaire: {
        ...genericQuestionnaire,
        softDependencies: [
          TranslationsBundles.saferGamblingQuestionnaire,
        ],
      },
      allowanceQuestionnaire: {
        ...genericQuestionnaire,
        softDependencies: [
          TranslationsBundles.allowanceQuestionnaire,
        ],
      },
      stakeLimits: {
        ...stakeLimitsCommon,
        softDependencies: [
          TranslationsBundles.stakeLimits,
        ],
      },
      lossLimits: {
        ...stakeLimitsCommon,
        softDependencies: [
          TranslationsBundles.stakeLimits,
          TranslationsBundles.lossLimits,
        ],
      },
      depositLimits: {
        app: JSBundles.depositLimits,
        dependencies: [
          CSSBundles.accountForms,
        ],
        getter: () => window.DepositLimits,
      },
    },
  },
  twoFACode: {
    app: JSBundles.twoFACodeFeedback,
    dependencies: [
      CSSBundles.twofa,
      CSSBundles.sendVerifyCode,
    ],
    getter: () => window.TwoFACodeFeedback,
  },
  twoFADeviceManager: {
    app: JSBundles.twoFADeviceManager,
    dependencies: [
      CSSBundles.twofa,
    ],
    getter: () => window.TwoFADeviceManager,
  },
  twoFAEnable: {
    app: JSBundles.twoFAEnableMethod,
    dependencies: [
      CSSBundles.twofa,
      CSSBundles.sendVerifyCode,
    ],
    getter: () => window.TwoFAEnableMethod,
  },
  twoFARegisterPhone: {
    app: JSBundles.twoFARegisterPhoneNumber,
    dependencies: [
      CSSBundles.twofa,
      CSSBundles.sendVerifyCode,
      CSSBundles.accountForms,
      CSSBundles.intTelInput,
    ],
    getter: () => window.TwoFARegisterPhoneNumber,
  },
  twoFATotpScan: {
    app: JSBundles.twoFATotpScan,
    dependencies: [
      CSSBundles.twofa,
      CSSBundles.sendVerifyCode,
    ],
    getter: () => window.TwoFATotpScan,
  },
  twoFATotp: {
    app: JSBundles.twoFATotpIntro,
    dependencies: [
      CSSBundles.twofa,
      CSSBundles.sendVerifyCode,
    ],
    getter: () => window.TwoFATotpIntro,
  },
  twoFAVerifyPassword: {
    app: JSBundles.twoFAInsertPassword,
    dependencies: [
      CSSBundles.twofa,
    ],
    getter: () => window.TwoFAInsertPassword,
  },
  login: {
    app: JSBundles.loginModal,
    dependencies: [
      CSSBundles.login,
      CSSBundles.accountForms,
    ],
    getter: () => window.LoginModal,
  },
  search: {
    app: JSBundles.search,
    dependencies: [
      CSSBundles.search,
    ],
    softDependencies: [
      TranslationsBundles.search,
    ],
    getter: () => window.Search,
    features: {
      casino: {
        app: JSBundles.casinoSearch,
        dependencies: [
          JSBundles.casinoGlobal,
          CSSBundles.casinoV2,
        ],
        getter: () => window.CasinoSearch,
      },
    },
  },
  asiaCashier: {
    app: JSBundles.asiaCashier,
    dependencies: [
      CSSBundles.asiaCashier,
      CSSBundles.accountForms,
      CSSBundles.liveChat,
    ],
    softDependencies: [
      TranslationsBundles.quickDeposit,
    ],
    getter: () => window.AsiaCashier,
  },
  emailVerification: {
    app: JSBundles.emailVerification,
    getter: () => window.EmailVerificationComponent,
  },
  casino: {
    app: JSBundles.casinov2,
    dependencies: [
      CSSBundles.casinoV2,
      CSSBundles.banners,
      JSBundles.banners,
      JSBundles.casinoGlobal,
      JSBundles.panicButton,
      CSSBundles.panicButton,
    ],
    softDependencies: [
      TranslationsBundles.panicButton,
    ],
    getter: () => window.CasinoV2,
  },
  virtuals: {
    app: JSBundles.virtualSports,
    dependencies: [
      CSSBundles.virtualSports,
      JSBundles.sportsbook,
    ],
    getter: () => window.VirtualsComponent,
  },
  sportsHome: {
    app: JSBundles.home,
    dependencies: [
      CSSBundles.banners,
      JSBundles.banners,
      JSBundles.homeComponents,
    ],
    getter: () => window.HomeComponent,
  },
  sportHome: {
    app: JSBundles.sportHome,
    dependencies: [
      JSBundles.sportsbook,
      CSSBundles.mediaStreaming,
    ],
    getter: () => window.SportHome,
  },
  competitions: {
    ...sportSections,
    getter: () => ({ default: window.SportSections.Competitions }),
  },
  selfExclude,
  lottery: {
    app: JSBundles.lottoland,
    dependencies: [
      CSSBundles.banners,
      JSBundles.banners,
    ],
    getter: () => window.CasinoLottoland,
  },
  betCalculator: {
    app: JSBundles.betCalculator,
    dependencies: [
      CSSBundles.betCalculator,
    ],
    getter: () => window.BetCalculator,
  },
  miniGames: {
    app: JSBundles.miniGames,
    dependencies: [
      JSBundles.casinoGlobal,
      CSSBundles.casinoV2,
    ],
    getter: () => window.MiniGames,
  },
  miniLaunch: {
    app: JSBundles.miniLaunch,
    dependencies: [
      JSBundles.casinoGlobal,
      CSSBundles.casinoV2,
    ],
    getter: () => window.MiniLaunch,
  },
  casinoGameLaunch: {
    app: JSBundles.casinoGameLaunch,
    getter: () => window.CasinoGameLaunch,
    softDependencies: [
      TranslationsBundles.casinoRegulations,
    ],
  },
  casinoFtpGameLaunch: {
    app: JSBundles.casinoFtpGameLaunch,
    getter: () => window.CasinoFtpGameLaunch,
    softDependencies: [
      TranslationsBundles.ftpGames,
    ],
  },
  casinoOverlay: {
    app: JSBundles.casinoOverlay,
    getter: () => window.CasinoOverlay,
  },
  offers: {
    app: JSBundles.offers,
    dependencies: [
      JSBundles.sportsbook,
      CSSBundles.errorPage,
      CSSBundles.offers,
      CSSBundles.accountForms,
      CSSBundles.banners,
      JSBundles.banners,
    ],
    getter: () => window.Offers,
  },
  quickDeposit: {
    app: JSBundles.quickDeposit,
    dependencies: [
      CSSBundles.quickDeposit,
      // TODO: Create a cashier/global JS and css bundles for pending withdrawals
      // And to be extended by shared things (cashier form, etc)
      CSSBundles.cashierActions,
    ],
    softDependencies: [
      TranslationsBundles.quickDeposit,
      TranslationsBundles.payments,
    ],
    getter: () => window.QuickDeposit,
  },
  staticPages: {
    app: JSBundles.staticPages,
    getter: () => window.StaticPagesComponent,
  },
  errorPage: {
    app: JSBundles.errorPage,
    dependencies: [
      CSSBundles.errorPage,
    ],
    getter: () => window.ErrorPageComponent,
  },
  mediaStreaming: {
    app: JSBundles.mediaStreaming,
    dependencies: [
      CSSBundles.mediaStreaming,
    ],
    getter: () => window.MediaStreaming,
  },
  betBuilderFixtures: {
    app: JSBundles.betBuilderFixtures,
    dependencies: [
      CSSBundles.betBuilderFixtures,
    ],
    getter: () => window.BetBuilderFixtures,
  },
  betBuilder: {
    app: JSBundles.betBuilder,
    dependencies: [
      CSSBundles.betBuilder,
      JSBundles.sportsbook,
    ],
    getter: () => window.BetBuilder,
  },
  greyHounds: {
    app: JSBundles.greyHounds,
    dependencies: [
      JSBundles.sportsbook,
      CSSBundles.greyhounds,
    ],
    getter: () => window.GreyHounds,
  },
  greyHoundsTimeTable: {
    app: JSBundles.greyHoundsTimeTable,
    getter: () => window.GreyHoundsTimeTable,
  },
  sportSectionsCustomList: {
    ...sportSections,
    getter: () => ({ default: window.SportSections.CustomList }),
  },
  customerConsentModal: {
    app: JSBundles.consentModal,
    dependencies: [],
    getter: () => window.ConsentModal,
  },
  fundsProtectionModal: {
    app: JSBundles.fundsProtectionModal,
    dependencies: [],
    getter: () => window.FundsProtectionModal,
  },
  inPlayOverview: {
    app: JSBundles.inPlayOverviewComponents,
    dependencies: [
      JSBundles.sportsbook,
      JSBundles.sportsbookCommon,
      CSSBundles.inPlayOverview,
    ],
    getter: () => ({ default: window.InPlayOverviewComponents.InPlayOverviewComponent }),
  },
  inPlayWidget: {
    app: JSBundles.inPlayOverviewComponents,
    dependencies: [
      JSBundles.sportsbook,
      JSBundles.sportsbookCommon,
      CSSBundles.inPlayOverview,
    ],
    getter: () => ({ default: window.InPlayOverviewComponents.InPlayWidget }),
  },
  betFinder: {
    app: JSBundles.betFinder,
    dependencies: [
      JSBundles.sportsbook,
      CSSBundles.betFinder,
    ],
    getter: () => window.BetFinderComponent,
  },
  eventComponents: {
    app: JSBundles.eventComponents,
    dependencies: [
      JSBundles.sportsbook,
      CSSBundles.eventComponents,
    ],
    getter: () => window.EventComponents,
  },
  eventLevel: {
    app: JSBundles.eventLevel,
    dependencies: [
      CSSBundles.priceItUp,
      JSBundles.sportsbook,
      JSBundles.scoreboard,
      CSSBundles.eventLevel,
      CSSBundles.scoreboard,
      CSSBundles.betBoost,
    ],
    softDependencies: [
      TranslationsBundles.eventLevel,
    ],
    getter: () => window.EventLevel,
  },
  scoreboard: {
    app: JSBundles.scoreboard,
    getter: () => window.Scoreboard,
  },
  horseRacing: {
    app: JSBundles.horseRacingRacecard,
    dependencies: [
      JSBundles.sportsbook,
      VCSTATE.EVENT_COMPONENTS_BUNDLE_PATH,
      CSSBundles.priceItUp,
      CSSBundles.racecard,
      CSSBundles.eventComponents,
      CSSBundles.eventLevel, // TODO: convert event level to generic sportsbook styles
      CSSBundles.scoreboard,
      CSSBundles.mediaStreaming,
    ],
    getter: () => window.HorseRacing,
  },
  timeOutBanner: {
    app: JSBundles.timeOutBanner,
    dependencies: [
      CSSBundles.timeOutBanner,
    ],
    getter: () => window.TimeOutBanner,
  },
  saferGamblingLanding: {
    app: JSBundles.saferGamblingLanding,
    dependencies: [
      CSSBundles.saferGamblingLanding,
    ],
    softDependencies: [
      TranslationsBundles.saferGamblingQuestionnaire,
    ],
    getter: () => window.SaferGamblingLanding,
  },
  layoutApps: {
    leftComponents: {
      app: JSBundles.layoutApps,
      getter: () => ({ default: window.LayoutApps.LeftComponents }),
    },
    smartAppBanner: {
      app: JSBundles.layoutApps,
      dependencies: [
        CSSBundles.appSmartBanner,
      ],
      getter: () => ({ default: window.LayoutApps.SmartAppBanner }),
    },
    floatingNavigation: {
      app: JSBundles.layoutApps,
      dependencies: [
        CSSBundles.floatingNavigation,
      ],
      getter: () => ({ default: window.LayoutApps.FloatingNavigation }),
    },
    modalHelper: {
      app: JSBundles.layoutApps,
      getter: () => ({ default: window.LayoutApps.ModalHelper }),
    },
  },
  rightSidebar: {
    app: JSBundles.rightSidebar,
    softDependencies: [
      TranslationsBundles.betslipBetTypeTooltips,
    ],
    getter: () => window.RightSidebarApp,
  },
  betBoost: {
    app: JSBundles.betBoost,
    dependencies: [
      CSSBundles.betBoost,
      CSSBundles.eventLevel,
      JSBundles.sportsbook,
    ],
    getter: () => window.SportsbookBetBoost,
  },
  luckyDipComponent: {
    app: JSBundles.luckyDip,
    dependencies: [
      JSBundles.sportsbook,
      CSSBundles.luckyDip,
    ],
    getter: () => ({ default: window.LuckyDip.LuckyDipComponent }),
  },
  luckyDipBetslip: {
    app: JSBundles.luckyDip,
    dependencies: [
      JSBundles.sportsbook,
    ],
    getter: () => ({ default: window.LuckyDip.LuckyDipBetslip }),
  },
  nextEvent: {
    app: JSBundles.nextEvent,
    dependencies: [],
    getter: () => window.NextEvent,
  },

  sportsbookFtpSportLaunch: {
    app: JSBundles.sportsbookFtpSportLaunch,
    dependencies: [
      CSSBundles.sportsbookFtpSportLaunch,
    ],
    getter: () => window.SportsbookFtpSportLaunch,
  },

  enhancedOutcomeOffers: {
    app: JSBundles.enhancedOutcomeOffers,
    dependencies: [],
    getter: () => window.EnhancedOutcomeOffers,
  },
  acceptAllPriceChangesToggle: {
    app: JSBundles.acceptAllPriceChangesToggle,
    dependencies: [],
    softDependencies: [
      TranslationsBundles.acceptAllPriceChangesToggle,
    ],
    getter: () => window.AcceptAllPriceChangesToggle,
  },
  acceptAllLineChangesToggle: {
    app: JSBundles.acceptAllLineChangesToggle,
    dependencies: [],
    softDependencies: [
      TranslationsBundles.acceptAllLineChangesToggle,
    ],
    getter: () => window.AcceptAllLineChangesToggle,
  },
  myTransactions: {
    app: JSBundles.myTransactions,
    dependencies: [
      CSSBundles.myTransactions,
    ],
    softDependencies: [
      TranslationsBundles.myTransactions,
    ],
    getter: () => window.MyTransactions,
  },
  zendeskLiveChat: {
    app: JSBundles.zendesk,
    dependencies: [
      JSBundles.zendeskWebSDKUrl,
      CSSBundles.zendeskLiveChat,
    ],
    softDependencies: [
      TranslationsBundles.zendesk,
    ],
    getter: () => window.Zendesk,
  },
  sportsbookBetslip: {
    app: JSBundles.sportsbookBetslip,
    dependencies: [
      JSBundles.sportsbook,
      JSBundles.acceptAllPriceChangesToggle,
      JSBundles.acceptAllLineChangesToggle,
      CSSBundles.betslipV2,
      JSBundles.panicButton,
      CSSBundles.panicButton,
    ],
    softDependencies: [
      TranslationsBundles.betslip,
      TranslationsBundles.acceptAllPriceChangesToggle,
      TranslationsBundles.acceptAllLineChangesToggle,
      TranslationsBundles.panicButton,
    ],
    getter: () => window.SportsbookBetslip,
  },
  sportsbookCashout: {
    app: JSBundles.sportsbookCashout,
    dependencies: [
      JSBundles.sportsbook,
      CSSBundles.cashoutV2,
      CSSBundles.inPlayOverview,
      JSBundles.sportsbookCommon,
    ],
    getter: () => window.SportsbookCashout,
  },
  selfExclusionSuccessModal: {
    app: JSBundles.selfExclusionSuccessModal,
    dependencies: [
      CSSBundles.selfExclude,
    ],
    softDependencies: [
      TranslationsBundles.selfExclusion,
    ],
    getter: () => window.SelfExclusionSuccessModal,
  },
  stakeLimitsBreachModal: {
    app: JSBundles.stakeLimitsBreachModal,
    dependencies: [
      CSSBundles.stakeLimits,
    ],
    softDependencies: [
      TranslationsBundles.stakeLimits,
    ],
    getter: () => window.StakeLimitsBreachModal,
  },
  lossLimitsBreachModal: {
    app: JSBundles.lossLimitsBreachModal,
    dependencies: [
      CSSBundles.stakeLimits,
    ],
    softDependencies: [
      TranslationsBundles.lossLimits,
    ],
    getter: () => window.LossLimitsBreachModal,
  },
  panicButton: {
    app: JSBundles.panicButton,
    dependencies: [
      CSSBundles.panicButton,
    ],
    softDependencies: [
      TranslationsBundles.panicButton,
    ],
    getter: () => window.PanicButton,
  },
  occupationAndSalary,
  crackTheCodeZone: {
    app: JSBundles.crackTheCodeZone,
    dependencies: [
      CSSBundles.crackTheCodeZone,
      CSSBundles.banners,
      JSBundles.banners,
      JSBundles.homeComponents,
      ...BVVars?.siteConfigs?.global?.hasSportsbook ? [
        JSBundles.sportsbook,
        JSBundles.sportsbookCommon,
      ] : [],
    ],
    getter: () => window.CrackTheCodeZone,
  },
  crackTheCodeProgress: {
    app: JSBundles.crackTheCodeProgress,
    dependencies: [
      CSSBundles.crackTheCodeProgress,
    ],
    getter: () => window.CrackTheCodeProgress,
  },
  kycRefresh: {
    app: JSBundles.KycRefresh,
    dependencies: [
      JSBundles.validations,
      CSSBundles.personalDetails,
      CSSBundles.accountForms,
      CSSBundles.intTelInput,
    ],
    softDependencies: [
      TranslationsBundles.saferGamblingPortal,
    ],
    getter: () => window.KycRefresh,
  },
};
